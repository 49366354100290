import {
  FundingSource,
  namedOperations,
  PaymentMethod,
  useDeactivateFundingSourceMutation,
  useDeletePaymentMethodMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import FundingSourceStatusBadge from "@earnnest-e2-frontend/platform-ui/src/Badge/FundingSourceStatusBadge"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import IconButton from "@earnnest-e2-frontend/platform-ui/src/IconButton"
import {
  AlertIcon,
  CircularIcon,
  DollarSignIcon,
  PlusIcon,
  TrashIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import Radio from "@earnnest-e2-frontend/platform-ui/src/Radio"
import { useToast } from "@earnnest-e2-frontend/platform-ui/src/Toast"
import { Box, Text, useTheme } from "@earnnest-e2-frontend/platform-ui/src/UI"
import moment from "moment"
import { useEffect, useState } from "react"
import VerifyMDButton from "./VerifyMDButton"

export type PaymentSelection = {
  type: "bank_account" | "card"
  id: string
}

interface FormProps {
  initialPaymentSelection: PaymentSelection | null
  onAddFundingSource: () => void
  onPaymentSelection: (x: PaymentSelection) => void | Promise<void>
  acceptsCreditCards?: boolean
}

export default function ManageFundingSourcesForm({
  initialPaymentSelection,
  onAddFundingSource,
  onPaymentSelection,
  acceptsCreditCards,
}: FormProps) {
  const { triggerToast } = useToast()
  const { getColor } = useTheme()
  const { track } = useEarnnestAnalytics()

  const [selecting, setSelecting] = useState(false)

  const [
    paymentSelection,
    setPaymentSelection,
  ] = useState<PaymentSelection | null>(initialPaymentSelection)

  const [deletePaymentMethod] = useDeletePaymentMethodMutation({
    refetchQueries: [namedOperations.Query.User],
  })

  const [deactivateFundingSource] = useDeactivateFundingSourceMutation({
    refetchQueries: [namedOperations.Query.User],
  })

  const [editingPaymentMethods, setEditingPaymentMethods] = useState(false)

  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  useEffect(() => {
    track("Funding Source List Viewed")
  }, [track])

  const editPaymentMethods = async () => {
    setPaymentSelection(null)
    setEditingPaymentMethods(true)
  }

  // wrapper for payment method removal that prompts the user to confirm
  // a removal function for CC or FS can be supplied
  const confirmPaymentMethodRemoval = async (
    removePaymentMethodFunc,
    paymentMethodId,
  ) => {
    if (
      window.confirm("Are you sure you want to delete this payment method?")
    ) {
      removePaymentMethodFunc(paymentMethodId)
    }
  }
  const handleDeletePaymentMethod = async (paymentMethodId) => {
    try {
      await deletePaymentMethod({
        variables: { paymentMethodId },
      })
      triggerToast({
        message: "Successfully deleted payment method.",
        kind: "success",
      })
      setEditingPaymentMethods(false)
    } catch (e) {
      triggerToast({
        message: "There was an error deleting payment method.",
        kind: "error",
      })
    }
  }

  const handleDeleteFundingSource = async (fundingSourceId) => {
    try {
      await deactivateFundingSource({
        variables: { fundingSourceId },
      })
      triggerToast({
        message: "Successfully deleted payment method.",
        kind: "success",
      })
      setEditingPaymentMethods(false)
    } catch (e) {
      triggerToast({
        message: "There was an error deleting payment method.",
        kind: "error",
      })
    }
  }

  let selectedFundingSource: FundingSource | null = null
  let selectedPaymentMethod: PaymentMethod | null = null

  let pendingFundingSources: any = []
  let verifiedFundingSources: any = []
  user?.fundingSources.forEach((fundingSource) => {
    if (
      paymentSelection?.type === "bank_account" &&
      paymentSelection?.id === fundingSource.id
    ) {
      // @ts-ignore
      selectedFundingSource = fundingSource
    }
    if (fundingSource.status === "VERIFIED") {
      verifiedFundingSources.push(fundingSource)
    } else {
      pendingFundingSources.push(fundingSource)
    }
  })

  let unexpiredPaymentMethods = []
  if (acceptsCreditCards) {
    user?.paymentMethods.forEach((paymentMethod) => {
      let expireMoment = moment()
        .set("year", paymentMethod.expYear)
        .set("month", paymentMethod.expMonth - 1) // moment months are zero-indexed
      if (moment().isBefore(expireMoment)) {
        if (
          paymentSelection?.type === "card" &&
          paymentSelection?.id === paymentMethod.id
        ) {
          selectedPaymentMethod = paymentMethod
        }
        unexpiredPaymentMethods.push(paymentMethod)
      }
    })
  }

  return (
    // @ts-ignore: No overload error
    <Box>
      <Box pb={56}>
        <Box pb={16} style={{ alignSelf: "center" }}>
          <CircularIcon
            Icon={DollarSignIcon}
            size={64}
            iconRatio={0.6}
            backgroundColor="contrast0"
            color="contrast90"
          />
        </Box>
        <Text type="heading3" style={{ textAlign: "center" }}>
          Select Payment Method
        </Text>
      </Box>
      {unexpiredPaymentMethods.length || verifiedFundingSources.length ? (
        <Box mb={32}>
          {!editingPaymentMethods ? (
            <Text
              type="heading5"
              style={{ textAlign: "right" }}
              onPress={() => editPaymentMethods()}>
              Edit Payment Methods
            </Text>
          ) : (
            <Text
              type="heading5"
              style={{ textAlign: "right" }}
              onPress={() => {
                setEditingPaymentMethods(false)
              }}>
              Back to Payment Methods
            </Text>
          )}
        </Box>
      ) : null}
      <form onSubmit={(e) => e.preventDefault()}>
        {verifiedFundingSources.map((fundingSource) => (
          <Box
            key={fundingSource.id}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            pt={8}>
            <Radio
              value={fundingSource.id}
              checked={paymentSelection?.id === fundingSource.id}
              disabled={editingPaymentMethods}
              onChange={() =>
                setPaymentSelection({
                  type: "bank_account",
                  id: fundingSource.id,
                })
              }>
              <Text type="heading4">{fundingSource.displayName}</Text>
            </Radio>
            {editingPaymentMethods && (
              <Box>
                <IconButton
                  Icon={TrashIcon}
                  backgroundColor="red"
                  color="white"
                  size={24}
                  onPress={() => {
                    confirmPaymentMethodRemoval(
                      handleDeleteFundingSource,
                      fundingSource.id,
                    )
                  }}
                />
              </Box>
            )}
          </Box>
        ))}
        {unexpiredPaymentMethods.map((paymentMethod) => (
          <Box
            key={paymentMethod.id}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            pt={8}>
            <Radio
              value={paymentMethod.id}
              checked={paymentSelection?.id === paymentMethod.id}
              disabled={editingPaymentMethods}
              onChange={() =>
                setPaymentSelection({
                  type: "card",
                  id: paymentMethod.id,
                })
              }>
              <Text type="heading4">
                {paymentMethod.brand.toUpperCase()} ending in{" "}
                {paymentMethod.last4}
              </Text>
            </Radio>
            {editingPaymentMethods && (
              <Box>
                <IconButton
                  Icon={TrashIcon}
                  backgroundColor="red"
                  color="white"
                  size={24}
                  onPress={() => {
                    confirmPaymentMethodRemoval(
                      handleDeletePaymentMethod,
                      paymentMethod.id,
                    )
                  }}
                />
              </Box>
            )}
          </Box>
        ))}
        <Box pt={2} pb={56} style={{ height: 104 }}>
          {!editingPaymentMethods && (
            <Button
              alignTitle="left"
              kind="textPrimary"
              onPress={onAddFundingSource}
              size="lg"
              title="Add payment method"
              LeftIcon={() => <CircularIcon Icon={PlusIcon} size={24} />}
            />
          )}
        </Box>
        <Box pb={56} style={{ height: 104 }}>
          {selectedPaymentMethod ? (
            <Button
              disabled={selecting}
              title={
                selecting
                  ? "Saving selection..."
                  : `Use ${selectedPaymentMethod.brand.toUpperCase()} ending in ${
                      selectedPaymentMethod.last4
                    }`
              }
              size="lg"
              style={{ width: 420, alignSelf: "center" }}
              onPress={async () => {
                try {
                  setSelecting(true)
                  await onPaymentSelection(paymentSelection)
                } catch (error) {
                  triggerToast({
                    kind: "error",
                    message: error.message,
                  })
                } finally {
                  setSelecting(false)
                }
              }}
            />
          ) : selectedFundingSource ? (
            <Button
              title={
                selecting
                  ? "Saving selection..."
                  : `Use ${selectedFundingSource.displayName}`
              }
              size="lg"
              style={{ width: 420, alignSelf: "center" }}
              onPress={() => onPaymentSelection(paymentSelection)}
            />
          ) : null}
        </Box>
      </form>
      {pendingFundingSources.length ? (
        <Box
          pb={16}
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box pr={8}>
            <AlertIcon style={{ color: getColor("yellow") }} />
          </Box>
          <Text type="heading4">These accounts are pending</Text>
        </Box>
      ) : null}
      {pendingFundingSources.map((fundingSource) => (
        <Box
          key={fundingSource.id}
          pb={16}
          style={{
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}>
          <Text
            type="heading4"
            style={{ flex: 1 }}
            numberOfLines={1}
            ellipsizeMode="tail">
            {fundingSource.displayName}
          </Text>
          {fundingSource.status === "PENDING_AUTOMATIC_MICRODEPOSIT" ? (
            <FundingSourceStatusBadge fundingSource={fundingSource} />
          ) : null}
          {fundingSource.status === "MICRODEPOSIT_READY" ||
          fundingSource.status === "PENDING_MANUAL_MICRODEPOSIT" ? (
            <VerifyMDButton
              fundingSourceId={fundingSource.id}
              kind="subtle"
              size="sm"
            />
          ) : null}
        </Box>
      ))}
    </Box>
  )
}

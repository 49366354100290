import { TransactionTemplateFragment } from "@earnnest-e2-frontend/platform-api/src/graphql"
import {
  ActionIcon,
  Button,
  Checkbox,
  Container,
  Group,
  Image,
  Overlay,
  Paper,
  ScrollArea,
  Space,
  Text,
  Title,
} from "@mantine/core"
import { useLocalStorage } from "@mantine/hooks"
import moment from "moment"
import { useState } from "react"
import { RiCloseLine } from "react-icons/ri"

const BLOCKLIST = [
  "Compass, Inc.",
  "Rockhaven Homes, LLC",
  "Howard Hanna Real Estate Services",
  "Ashbrooke Home Builders, Inc.",
  "Autumn Brook Holdings, LLC",
  "Baldwin Home Builders, LLC",
  "Cornerstone Fairburn Holdings, LLC",
  "Cornerstone Fulton Home Builders, Inc.",
  "High Grove Home Builders, Inc.",
  "Hoschton Towns, LLC",
  "Jefferson Downs Home Builders, Inc.",
  "Jonesboro Home Builders, Inc.",
  "Keswick Home Builders, Inc.",
  "Parks at Browns Mill Home Builders, Inc.",
  "RH Lot Home Builders, Inc.",
  "Stonecrest Home Builders, Inc.",
  "Trinity Knight, LLC",
  "Howard Hanna Premier Properties By Barbara Alexander, LLC",
  "Howard Hanna",
  "Howard Hanna Simon Real Estate Services",
  "Howard Hanna The Frederick Group",
  "Howard Hanna Real Estate Services",
  "Title Forward - California Inc.",
  "Title Forward - Washington",
  "Title Forward - Colorado",
  "Title Forward - Maryland",
  "Title Forward - Florida",
  "Title Forward - Texas",
  "Title Forward - Virginia",
  "Title Forward - National",
  "Meybohm Real Estate",
  "Meybohm Commercial Properties, LLC",
  "Bolst, Inc.",
  "Kennon, Parker, Duncan & Davis LLC",
  "GVMRE LLC",
  "Berkshire Hathaway HomeServices Indiana Realty",
  "KW Success",
  "KW St. George",
  "Absolute Title Agency, LLC",
]

// const PROMOTION = {
//   id: "TR",
//   heading: "Transactly",
//   description: "",
//   prompt: "Send me free recommendations for services in my area",
//   image: "/transactly-banner.png",
//   convert: async (transactionTemplate: TransactionTemplateFragment) => {
//     const payload = {
//       rentOrOwn: "Own",
//       moveInDate: moment().add(30, "days").format("YYYY-MM-DD"),
//       firstName: transactionTemplate.buyer?.firstName || "",
//       lastName: transactionTemplate.buyer?.lastName || "",
//       email: transactionTemplate.buyer?.email || "",
//       phone: transactionTemplate.buyer?.phone || "",
//       address: "",
//       city: "",
//       state_province: "",
//       zip: "",
//     }
//     transactionTemplate.formData?.forEach((field) => {
//       if (field.name === "address_line_1") payload.address = field.value
//       if (field.name === "city") payload.city = field.value
//       if (field.name === "state_or_region") payload.state_province = field.value
//       if (field.name === "postal_code") payload.zip = field.value
//     })
//     await fetch(
//       "https://earnnest-sf-forms.netlify.app/.netlify/functions/transactlyLead",
//       {
//         method: "POST",
//         body: JSON.stringify(payload),
//       },
//     )
//   },
// }

const PROMOTION = {
  id: "UC",
  heading: "Utility Concierge",
  description: `Thank you for authorizing payment! There’s a lot to do when it’s time to move. Utility Concierge makes sure all of your utilities and home services (electricity, internet, cable, home security, etc.) are set up and ready to use by the time you move in. Best of all, this service is completely free because you are working with Earnnest!`,
  prompt: "Send me additional information",
  image: "/utility-concierge-banner.jpg",
  convert: async (transactionTemplate: TransactionTemplateFragment) => {
    const isProd = process.env.REACT_APP_ENVIRONMENT === "prod"
    const url = `https://hooks.zapier.com/hooks/catch/10787526/${isProd ? "bpp6in8" : "bxv3pos"}/` // prettier-ignore
    const payload = {
      "Date/Time": moment().toISOString(),
      NAME: transactionTemplate.buyer?.fullName || "",
      EMAIL: transactionTemplate.buyer?.email || "",
      PHONE: transactionTemplate.buyer?.phone || "",
      ADDRESS: "",
      CITY: "",
      STATE: "",
      ZIP: "",
    }
    transactionTemplate.formData?.forEach((field) => {
      if (field.name === "address_line_1") payload.ADDRESS = field.value
      if (field.name === "city") payload.CITY = field.value
      if (field.name === "state_or_region") payload.STATE = field.value
      if (field.name === "postal_code") payload.ZIP = field.value
    })
    await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
    })
  },
}

export default function PromotionOverlayForm({
  transactionTemplate,
}: {
  transactionTemplate: TransactionTemplateFragment
}) {
  const [viewed, setViewed] = useLocalStorage({
    key: `promotion-viewed-${transactionTemplate?.id}`,
    defaultValue: "",
  })

  const [checked, setChecked] = useState(false)

  if (
    viewed ||
    BLOCKLIST.includes(transactionTemplate?.escrowAccount?.organization?.name)
  ) {
    return null
  }

  return (
    <Overlay bg="dark.6">
      <ScrollArea h="100vh">
        <Container size="sm" my={100}>
          <Paper p="xl">
            <Group align="start" position="apart" noWrap>
              <Title size="h2" order={2}>
                Free, because you used Earnnest!
              </Title>
              <ActionIcon
                size="md"
                color="dark"
                variant="light"
                onClick={() => setViewed(new Date().toISOString())}>
                <RiCloseLine size={24} />
              </ActionIcon>
            </Group>
            <Text sx={{ whiteSpace: "pre-wrap" }}>{PROMOTION.description}</Text>
            <Space h="xs" />
            <Image
              src={PROMOTION.image}
              height="auto"
              width="100%"
              sx={(theme) => ({
                borderRadius: theme.radius.md,
                border: `1px solid ${theme.fn.themeColor("gray.2")}`,
              })}
            />
            <Space h="xl" />
            <Checkbox
              size="lg"
              checked={checked}
              onChange={() => setChecked((x) => !x)}
              label={
                <>
                  <Text size="md" weight="bold">
                    {PROMOTION.prompt}
                  </Text>
                  <Text size="sm">
                    Earnnest will only share your name, email, phone number, and
                    address.
                  </Text>
                </>
              }
            />
            <Space h="xl" />
            <Button
              size="lg"
              color="green"
              fullWidth={true}
              onClick={async () => {
                try {
                  setViewed(new Date().toISOString())
                  if (checked) {
                    await PROMOTION.convert(transactionTemplate)
                  }
                } catch (error) {
                  console.error(error)
                  // swallow error, we don't want to disrupt user from seeing their payment confirmation
                }
              }}>
              Continue to payment confirmation
            </Button>
          </Paper>
        </Container>
      </ScrollArea>
    </Overlay>
  )
}
